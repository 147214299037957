import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const LegalEntityIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 24 18">
        <path
            fillRule="evenodd"
            d="M10.5 1A1.5 1.5 0 0 0 9 2.5V3h6v-.5A1.5 1.5 0 0 0 13.5 1h-3zM16 3v-.5A2.5 2.5 0 0 0 13.5 0h-3A2.5 2.5 0 0 0 8 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v12A1.5 1.5 0 0 0 1.5 18h21a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 22.5 3H16zM1.5 4a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5H5V4H1.5zM6 4v13h12V4H6zm13 0v13h3.5a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H19z"
        />
    </SvgIcon>
)
